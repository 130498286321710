<template>
  <div>
    <div class="site-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8 mb-5">
            <ContactForm/>
          </div>
          <div class="col-lg-4">
            <div class="p-4 mb-3 bg-white form-contact">
              <h3 class="h6 text-black mb-3 text-uppercase">{{$t("pages.contact.contact_info")}}</h3>
              <p v-if="info.address" class="mb-0 font-weight-bold site-fontcolor">{{$t("pages.contact.company_address")}}</p>
              <p v-if="info.address" class="mb-4">
                {{info.address}}
              </p>

              <p v-if="info.reserveemail" class="mb-0 font-weight-bold site-fontcolor" >{{$t("pages.contact.company_email")}}</p>
              <p v-if="info.reserveemail" class="mb-4">
                <a :href="`mailto:${info.reserveemail}`">{{
                info.reserveemail
              }}</a>
              </p>
              
              <p v-if="info.phone || info.reservephone" class="mb-0 font-weight-bold site-fontcolor">{{$t("pages.contact.company_phone")}}</p>
              <p v-if="info.phone || info.reservephone">
                <a v-if="info.phone" :href="`tel:${info.phone}`">
                  {{ info.phone }}
                </a>
                <br>
                <a v-if="info.reservephone" :href="`tel:${info.reservephone}`">
                  {{ info.reservephone }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/layout/ContactForm.vue";
import { mapGetters } from 'vuex'

export default {
  name: "app",
  components: {
    
    ContactForm

  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
};
</script>

<template>
  <div>
    <div class="container mt-5">
      <div v-if="!agents" class="text-center">
        <SectionLoader v-show="isVisible" />
        <NotResults v-show="noVis" code="208"/>
      </div>
      <div v-else class="row mb-5">
        <AgentCard
          v-for="(item, i) in agents"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :agent="item"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SectionLoader from "@/components/layout/SectionLoader.vue";
import AgentCard from "@/components/agents/AgentCard.vue";
import NotResults from '@/components/layout/NotFound';
export default {
  components: {
    SectionLoader,
    AgentCard,
    NotResults
  },
  data() {
    return {
        isVisible: true,
        noVis: false,
    };
  },
  mounted() {
    this.ShowLoaderSecs();
  },
  methods: {
    ShowLoaderSecs() {
      setTimeout(() => {
        this.isVisible = false;
        this.noVis=true;
      }, 1200);
      
    },
  },
  computed:{
        ...mapGetters({
            agents:'_getTeam',
            error:'_getError',
        }),
    },
    created(){
      this.$store.dispatch('getTeam');
    },
    
};
</script>

<template>
  <div v-if="agent" :class="{'col-md-6 col-lg-4 mb-4':nameRoute!='Agent'}">
    <div class="team-member text-center">
      <img  @click="goToAgent()" class="img-fluid rounded mb-4 imagenA" :src="agent.image ? agent.image : '/images/avatar.png'"  :alt="`${agent.fullname}-img`"/>

      <div class="text">
        <a @click="goToAgent(agent.folio,agent.fullname)" class="cc_pointer"><h2  class="mb-2 font-weight-light text-black h4">
          {{ agent.fullname }}
        </h2></a>
        <p v-if="agent.biografia">
          {{ agent.biografia }}
        </p>
        <span class="d-block mb-3 text-white-opacity-05">{{
          agent.cellphone
        }}</span>
         <span class="d-block mb-3 text-white-opacity-05">{{
          agent.email
        }}</span>
        <p>
          <a v-if="agent.fb" :href="agent.fb" class="text-black p-2"
            ><span class="icon-facebook"></span
          ></a>
          <a v-if="agent.instagram" :href="agent.instagram" class="text-black p-2"
            ><span class="icon-instagram"></span
          ></a>
          <a v-if="agent.youtube" :href="agent.youtube" class="text-black p-2"
            ><span class="icon-youtube"></span
          ></a>
          <a v-if="agent.tweet" :href="agent.tweet" class="text-black p-2"
            ><span class="icon-twitter"></span
          ></a>
          <a v-if="agent.linkedin" :href="agent.linkedin" class="text-black p-2"
            ><span class="icon-linkedin"></span
          ></a>
        </p>
        <button
          v-if="nameRoute!='Agent'"
          @click="goToAgent()"
          type="submit"
          class="btn btn-primary  py-2 px-4 rounded-0"
        >
          {{ $t("components.agent_card.link") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);
      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
.imagenA {
    width: 300px !important;
    height: 255px !important;
}
</style>
